/// <reference path="../groupthink-js.d.ts" />

import { axios, fetcher, apiRequest } from '../lib';
import useSWR, { mutate } from 'swr';
import { AxiosRequestConfig } from 'axios';

export const useThread = (
  agendaId?: string,
  id?: string,
  options?: {
    useRealtimeMutate?: (eventName: string, channel: string | null, url: string | null) => void;
  }
) => {
  const { useRealtimeMutate } = options || {};

  let url = agendaId && id ? `/v1/agendas/${agendaId}/threads/${id}` : false;

  // if the agendaId starts with the letters "RM" we need a different url
  if (url && agendaId?.startsWith('RM')) {
    url = `/v1/rooms/${agendaId}/threads/${id}`;
  }

  if (url && agendaId?.startsWith('DO')) {
    url = `/v1/documents/${agendaId}/threads/${id}`;
  }

  const key = url as string;

  const {
    data: thread,
    error,
    isLoading,
    mutate,
  } = useSWR<Groupthink.SuccessfulResponseContent<'thread.show'>>(url, fetcher, {
    keepPreviousData: true,
  });

  useRealtimeMutate?.('.ThreadUpdated', !agendaId ? null : `agendas.${agendaId}`, key);
  useRealtimeMutate?.('MessageSent', !id ? null : `threads.${id}.messages`, key);
  useRealtimeMutate?.('MessageReactedTo', !id ? null : `threads.${id}.messages`, key);

  const markThreadAsRead = <RouteName = 'thread.mark_as_read'>(
    message_id: string,
    {
      setErrors,
      setIsMarkingRead,
      onSuccess,
    }: Omit<Groupthink.BaseOperationOptions<RouteName>, 'payload'> & {
      setIsMarkingRead?: (isMarkingRead: boolean) => void;
    }
  ) =>
    apiRequest<RouteName>(`/v1/agendas/${agendaId}/threads/${id}/mark_as_read`, mutate, 'POST', {
      setErrors,
      setLoading: setIsMarkingRead,
      payload: { message_id },
      onSuccess,
    });

  return {
    thread: thread?.data,
    isLoading,
    isError: error,
    mutate,
    markThreadAsRead,
  };
};

export const useThreads = (
  agendaId?: string,
  options?: {
    useRealtimeMutate?: (eventName: string, channel: string | null, url: string | null) => void;
  }
) => {
  const { useRealtimeMutate } = options || {};

  let url = agendaId ? `/v1/agendas/${agendaId}/threads` : false;

  // if the agendaId starts with the letters "RM" we need a different url
  if (url && agendaId?.startsWith('RM')) {
    url = `/v1/rooms/${agendaId}/threads`;
  }

  if (url && agendaId?.startsWith('DO')) {
    url = `/v1/documents/${agendaId}/threads`;
  }

  const {
    data: thread,
    error,
    isLoading,
    mutate,
  } = useSWR<Groupthink.SuccessfulResponseContent<'thread.index'>>(() => url, fetcher, {
    keepPreviousData: true,
  });

  const createThread = <RouteName = 'thread.store'>({
    setErrors,
    setIsCreating,
    onSuccess,
    payload,
  }: Groupthink.CreateOperationOptions<RouteName>) =>
    apiRequest<RouteName>(`/v1/agendas/${agendaId}/threads`, mutate, 'POST', {
      setErrors,
      setLoading: setIsCreating,
      payload,
      onSuccess,
    });

  const updateThread = <RouteName = 'thread.update'>(
    threadId: string,
    { setErrors, setIsCreating, onSuccess, payload }: Groupthink.CreateOperationOptions<RouteName>
  ) =>
    apiRequest<RouteName>(`/v1/agendas/${agendaId}/threads/${threadId}`, mutate, 'PUT', {
      setErrors,
      setLoading: setIsCreating,
      payload,
      onSuccess,
    });

  const deleteThread = async <RouteName = 'thread.destroy'>(
    threadId: string,
    { setErrors, setIsDeleting, onSuccess }: Groupthink.DeleteOperationOptions<RouteName>
  ) =>
    apiRequest<RouteName>(`/v1/agendas/${agendaId}/threads/${threadId}`, mutate, 'DELETE', {
      setErrors,
      setLoading: setIsDeleting,
      onSuccess,
    });

  useRealtimeMutate?.(
    '.ThreadCreated',
    agendaId ? `agendas.${agendaId}` : null,
    agendaId ? `/v1/agendas/${agendaId}/threads` : null
  );

  return {
    threads: thread?.data,
    isLoading,
    isError: error,
    mutate,
    createThread,
    updateThread,
    deleteThread,
  };
};

export const manipulateThreads = async <RouteName>(
  url: string,
  {
    method = 'POST',
    payload,
  }: {
    method?: AxiosRequestConfig['method'];
    payload?: Groupthink.RequestPayload<RouteName> | Record<string, unknown>;
  }
) => {
  await axios(url, {
    method,
    data: payload,
  });
};

export const markThreadAsRead = (agendaId: string, id: string, message_id: string) =>
  mutate(
    (key) => typeof key === 'string' && key.startsWith(`/v1/agendas`),
    manipulateThreads(`/v1/agendas/${agendaId}/threads/${id}/mark_as_read`, {
      method: 'POST',
      payload: { message_id },
    })
  );
